import {createApp} from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import {createRouter, createWebHistory} from "vue-router";

const router = createRouter({
    history: createWebHistory(), routes: [
        {
            path: '/ex/:id',
            component: () => import('./pages/Exhibition.vue')
        },
        {
            path: '/hallmap/:eid/:hid',
            component: () => import('./pages/HallMap.vue')
        },
        {
            path: '/edithallmap/:eid/:hid',
            component: () => import('./pages/EditHallMap.vue')
        },
        {
            path: '/SearchHall',
            name: 'SearchHall',
            component: () => import('./pages/SearchHall.vue')
        }
    ]
})

const app = createApp(App)
// 添加meta标签，禁止屏幕缩放
const meta = document.createElement('meta')
meta.name = 'viewport'
meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
document.getElementsByTagName('head')[0].appendChild(meta)
app.use(router)
// if (isDebug()) {
//     app.use(new Vconsole());
// }
app.mount('#app')
