const isNull = function (data) {
    return data === null || typeof (data) == "undefined" || (data.toString().replace(/(^s*)|(s*$)/g, "").length === 0)
}

const printLog = function (data) {
    if (isDebug()) {
        console.log(data)
    }
}

const printDir = function (data) {
    if (isDebug()) {
        console.dir(data)
    }
}

const isDebug = function () {
    return process.env.NODE_ENV === 'development'
};

const getUrlParam = function (url, key) {
    var splits = url.split("?")
    if (splits && splits.length > 1) {
        var path = splits[1]
        if (path) {
            const kvArray = path.split("&")
            const size = kvArray ? kvArray.length : 0
            if (size > 0) {
                for (var i = 0; i < size; i++) {
                    const kv = kvArray[i].split("=")
                    if (kv && kv.length > 1) {
                        if (key == kv[0]) {
                            return kv[1]
                        }
                    }
                }
            }
        }
    }
    return null;
}

module.exports = {
    isNull,
    printLog,
    printDir,
    isDebug,
    getUrlParam
}