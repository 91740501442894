<template>

    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;
   padding: 0;margin: 0">
        <div ref="mapContainer" style="position: relative;width: 100%">
            <img v-if="this.canvasConfig" :src="this.canvasConfig.url"
                 style="object-fit: contain;width: 100%;height: auto;position: absolute">
            <div id="mapCanvas"
                 style="position: absolute;margin: auto;display: flex;justify-content: center;align-items: center;width: 100%">
            </div>
        </div>

        <div class="back-button" @click.stop="onBackClick">
            返回
        </div>
    </div>
</template>

<script>

import Raphael from 'raphael';
import api from "@/utils/api";
import {printDir, printLog} from "@/utils/commonUtils";


export default {
    data() {
        return {
            exhibitionId: 0,
            canvasConfig: null,
            raphaelObj: null,
            backHrefUrl: null,
            mapData: {
                animConfig: {
                    duration: 3000,
                    attr: {
                        stroke: 'red',
                        strokeWidth: 10,
                        strokeOpacity: 1,
                        fill: 'none',
                        fillOpacity: 0.0
                    }
                },
                cellList: null,
                image: null,
                mouseOver: {
                    fillColor: "#35b5c0",
                    strokeColor: "#1eee32",
                    textColor: "#7511a8"
                },
                mouseOut: {
                    fillColor: "#97d6f5",
                    strokeColor: "#7a7979",
                    textColor: "#000000"
                },
            }
        }
    },
    mounted() {
        this.exhibitionId = this.$route.params.id
        printLog("exid is:" + this.exhibitionId)
        this.getMapData()
    },
    methods: {
        onBackClick() {
            if (window.history.length > 1) {
                window.history.back();
            } else {
                window.location.href = this.backHrefUrl ? this.backHrefUrl : "index.html"
            }
        },


        getMapData() {

            api.get("/index.ashx?method=dg_zhanguan&zhid=" + this.exhibitionId).then(res => {
                printDir(res)
                const data = res
                this.backHrefUrl = data.backHrefUrl
                this.mapData.cellList = data.cellList
                this.mapData.image = data.image
                this.canvasConfig = this.getCanvasWH(this.mapData.image);
                this.canvasConfig.url = this.mapData.image ? this.mapData.image.url : ""
                this.raphaelObj = Raphael("mapCanvas", this.canvasConfig.width, this.canvasConfig.height);
                this.paintMap(this.mapData, this.canvasConfig);
            })
        },

        drawPointPath(pathObj) {
            var pointCount = pathObj.pointList.length;
            if (pointCount > 1) {
                const pathAttr = {
                    // "fill": "#97d6f5",
                    "stroke": pathObj.pathColor,
                    "stroke-width": 14,
                    "stroke-linejoin": "round"
                }
                var path = "M"
                pathObj.pointList.forEach((point) => {
                    path += point.x + "," + point.y + "L,"
                });
                this.deleteLastPath(pathObj.pathIndex)
                const p = this.raphaelObj.path(path).attr(pathAttr)
                p.id = pathObj.pathIndex
                printLog("draw router path is:" + path + ",color:" + pathObj.pathColor)


            }
        },


        getRectPath(ratio, pointArray, appendZ = true) {
            let pa = pointArray || []
            let len = pa.length
            let path = "";
            if (len > 0) {
                path += "M"
                pa.forEach((point, index) => {
                    const x = parseInt(point.x / ratio)
                    const y = parseInt(point.y / ratio)
                    path += x + "," + y + (index === len - 1 ? (appendZ ? "z" : "") : "L,")
                    pa.relPoint = {
                        x: x,
                        y: y
                    }
                })
            }
            return path
        },

        getCanvasWH(imageConfig) {
            var canvasWidth = window.innerWidth
            if (imageConfig) {
                const ratio = imageConfig.width / canvasWidth;
                const canvasHeight = parseInt(imageConfig.height / ratio);
                return {
                    width: canvasWidth,
                    height: canvasHeight,
                    ratio: ratio
                };
            } else {
                return {
                    width: canvasWidth,
                    height: canvasWidth,
                    ratio: 1
                };
            }
        },

        paintMap(mapData, config) {
            const ratio = config.ratio
            const that = this
            const cellList = mapData.cellList
            cellList.forEach((cell) => {
                const pathAttr = {
                    "fill": "#97d6f5",
                    "stroke": "#7a7979",
                    "stroke-width": 2,
                    "stroke-opacity": 0,
                    'fill-opacity': 0,
                    "stroke-linejoin": "round"
                }
                const pathObj = this.raphaelObj.path(this.getRectPath(ratio, cell.path.data)).attr(pathAttr)
                const pathView = pathObj[0]
                pathView.onclick = function () {
                    const id = cell.id;
                    // printLog("===Click cell " + cell.title.label + ",id:" + id);
                    that.$router.push({
                        path: '/hallmap/' + that.exhibitionId + "/" + id
                    })
                };


            })

        },

    },

}

</script>


<style>

.back-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    font-size: 30px;
    text-align: center;
    z-index: 999;
    line-height: 90px;
    vertical-align: middle;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #307FD1;
    color: white;
}
</style>