<template>
    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color: #f9f9f9;
   padding: 0;margin: 0">


        <div v-if="pageTitle" ref="topTitle" class="page-top-title" v-text="pageTitle"></div>
        <div v-if="mapData&&mapData.cellList" ref="topNav"
             class="navigator-panel">
            <div
                    style="display: flex;flex-direction: row;align-items: center;justify-content: center;flex-wrap: nowrap;width: 100%">
                <div class="navigator-list">
                    <input class="search-input-view1" style="margin-bottom: 10px;width: 100%" type="text"
                           placeholder="点击选择当前展馆起点位置"
                           v-model="this.startTargetLabel"
                           readonly
                           @click="onInputStartClick(0)">
                    <input class="search-input-view1" style="width: 100%" type="text"
                           placeholder="点击选择当前展馆终点位置"
                           v-model="this.endTargetLabel"
                           readonly
                           @click="onInputEndClick(1)">
                </div>
                <div class="gap-view"></div>
                <div class="search-input-button1" style="margin: 10px;padding: 6px;" @click="onSearchMapClick">
                    <img class="navigator-size" src="../assets/ic_navigator.png">
                    <label class="navigator-label">观展路径规划</label>
                </div>
            </div>


        </div>

        <div ref="mapContainerWrap" style="width: 100%;overflow: scroll;"
             :style="{height:this.mapHeight+'px'}">
            <div ref="mapContainer" style="position: relative;width: 100%;" :style="getMapStyle()">
                <img v-if="this.canvasConfig" :src="this.canvasConfig.url"
                     style="object-fit: contain;width: 100%;height: auto;position: absolute"
                >
                <div id="mapCanvas" @click="onMapClick"
                     style="position: absolute;margin: auto;display: flex;justify-content: center;align-items: center;width: 100%"
                >
                </div>
            </div>
        </div>

        <div class="back-button1" @click.stop="onBackClick">
            返回
        </div>


        <div class="hall-list-panel-wrap">
            <div v-if="this.hallList&&this.hallList.length>0" @click.prevent="onHallListClick" class="hall-list-panel">
                <img src="../assets/ic_arrow_up.png" class="hall-list-arrow" @click.prevent="onHallListUpClick">

                <div class="hall-list-container" ref="hallListScroller">

                    <label v-for="(item) of this.hallList"
                           :class="['hall-list-label',item.id==this.hallId?'hall-list-label-sel':'']" v-text="item.name"
                           @click="onHallLabelClick(item.id)">
                    </label>
                </div>
                <img src="../assets/ic_arrow_down.png" class="hall-list-arrow" @click.prevent="onHallListDownClick">
            </div>
            <div
                    class="zoom-panel"
                    v-if="this.canvasConfig" :src="this.canvasConfig.url">
                <img class="back-button3" style="object-fit: contain" @click.stop="onZoomUp"
                     src="../assets/ic_zoom_up.png"/>

                <div style="height: 10px"></div>
                <img class="back-button3" @click.stop="onZoomDown" style="object-fit: contain"
                     src="../assets/ic_zoom_down.png"/>

            </div>
        </div>


        <!-- 选择Page面板-->
        <div v-if="this.showSearchPanel"
             :class="[this.isShowSearchPanelAnim?'pop-in-from-bottom':'pop-out-from-top']"
             style="width: 100%;height: 100%;position: fixed;top: 0;bottom: 0;background-color: rgba(0,0,0,0.5);">

            <div style="height: 30%;width: 100%" @click="onCancelSearchClick"></div>
            <div style="height: 70%;background-color: white;width: 100%;border-radius: 6px;
            display: flex;flex-direction: column;align-items: center" @click="onSearchPanelClick">
                <div
                        style="width: 100%;display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                    <label class="path-title-label">选择路线起点与终点(当前展馆)</label>
                    <img src="../assets/ic_close_gray.png" class="path-panel-close" @click="onCancelSearchClick">
                </div>

                <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;width: 100%;">
                    <!--                    <input class="condition-radio1" type="radio" name="searchConditions" value="1" checked-->
                    <!--                           v-model="this.searchConditionType">-->
                    <!--                    <label class="condition-radio-label1">展商</label>-->
                    <!--                    <input class="condition-radio1" type="radio" name="searchConditions" value="2"-->
                    <!--                           v-model="this.searchConditionType">-->
                    <!--                    <label class="condition-radio-label1">展馆</label>-->
                    <!--                    <input class="condition-radio1" type="radio" name="searchConditions" value="3"-->
                    <!--                           v-model="this.searchConditionType">-->
                    <!--                    <label class="condition-radio-label1">分类</label>-->
                    <input type="text" name="searchKeyword" placeholder="请输入搜索关键字" v-model="searchKeywords"
                           class="search-condition-input1">
                    <div class="search-input-button2"
                         @click="onSearchConditionClick">
                        搜索
                    </div>

                </div>

                <div style="width: 100%;height: 1px;background-color: #d9d6d6;margin: 10px 0"></div>

                <div style="width: 100%">
                    <label :class="['page-title',this.pageTitleIndex===0?'page-title-sel':'']"
                           @click.prevent="onPageTitleClick(0)">展位</label>
                    <label :class="['page-title',this.pageTitleIndex===1?'page-title-sel':'']"
                           @click.prevent="onPageTitleClick(1)">服务点</label>
                    <label :class="['page-title',this.pageTitleIndex===2?'page-title-sel':'']"
                           @click.prevent="onPageTitleClick(2)">附近</label>
                </div>
                <div ref="searchPageView" style="width: 100%;background-color: #e7e8e8;display: flex;
                flex-direction: row;flex-wrap: nowrap;overflow-x: hidden;flex-grow: 1">
                    <div class="page-item">
                        <div v-if="!hasSearchResult()" class="no-content-page">暂无展位结果</div>
                        <div v-if="hasSearchResult()" class="has-content-page">
                            <div v-for="(item) of this.searchResultList" style="background-color: white;width: calc(100% - 20px);display: flex;flex-wrap: nowrap;
                            flex-direction: row;justify-content: center;align-items: center;border-bottom: gainsboro solid 1px;padding: 10px">
                                <div v-text="item.gsmc"
                                     class="search-result-label"
                                     @click="onTargetLabelClick(item)"></div>
                                <img src="../assets/ic_location.png" class="search-result-icon"
                                     @click="onLocationClick(item)">
                            </div>
                        </div>
                    </div>
                    <div class="page-item">
                        <div v-if="!hasServerPoint()" class="no-content-page">暂无服务点结果</div>
                        <div v-if="hasServerPoint()" class="has-content-page">
                            <div v-for="(item) of this.serverPointList" style="background-color: white;width: calc(100% - 20px);display: flex;flex-wrap: nowrap;
                            flex-direction: row;justify-content: center;align-items: center;border-bottom: gainsboro solid 1px;padding: 10px">
                                <div v-text="item.gsmc" class="search-result-label"
                                     @click="onTargetLabelClick(item)"></div>
                                <img src="../assets/ic_location.png" class="search-result-icon"
                                     @click="onLocationClick(item)">
                            </div>
                        </div>
                    </div>
                    <div class="page-item">
                        <div v-if="!hasNearbyPoint()" class="no-content-page">暂无附近结果</div>
                        <div v-if="hasNearbyPoint()" class="has-content-page">
                            <div v-for="(item) of this.nearbyPointList" style="background-color: white;width: calc(100% - 20px);display: flex;flex-wrap: nowrap;
                            flex-direction: row;justify-content: center;align-items: center;border-bottom: gainsboro solid 1px;padding: 10px">
                                <div v-text="item.gsmc" class="search-result-label"
                                     @click="onTargetLabelClick(item)"></div>
                                <img src="../assets/ic_location.png" class="search-result-icon"
                                     @click="onLocationClick(item)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!-- 起点和终点设置面板-->
        <div v-if="this.showSetStartAndEndPanel" style="width: 100%;height: 100%;position: fixed;top: 0;bottom: 0;background-color: rgba(0,0,0,0.5);

        display: flex;flex-direction: column;align-items: center;justify-content: center;"
             @click="onCancelSetStartAndEndClick">
            <div style="width: 80%;background-color: white;border-radius: 6px;padding: 20px"
                 @click.stop="onSetStartAndEndPanelClick">
                <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
                    <label v-text="'将  '+showTargetItem.gsmc" class="set-hall-label"></label>
                    <img src="../assets/ic_close_gray.png" class="path-panel-close"
                         @click="onCancelSetStartAndEndClick">
                </div>

                <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 30px">
                    <button class="set-hall-button" @click="onSetStartHallClick">设为起点</button>
                    <button class="set-hall-button" @click="onSetEndHallClick">设为终点</button>
                </div>
            </div>
        </div>


        <!--显示展馆跳转面板-->
        <div v-if="this.showHallPanel"
             :class="[this.isShowHallPanelAnim?'pop-in-from-bottom':'pop-out-from-top']"
             style="width: 100%;height: 100%;position: fixed;top: 0;bottom: 0;background-color: rgba(0,0,0,0.5);"
             @click="onCancelShowHallClick">
            <div style="width: 100%;background-color: white;border-radius: 6px;position: fixed;bottom: 0"
                 @click.stop="onSetStartAndEndPanelClick">


                <div v-if="showTargetItem"
                     style="display: flex;flex-direction: row;justify-content: space-between;margin: 10px 20px">
                    <label v-text="showTargetItem.title.label"></label>
                    <div>
                        <button class="set-hall-button" @click="onSetStartHallClick">设为起点</button>
                        <button class="set-hall-button" @click="onSetEndHallClick">设为终点</button>
                    </div>
                </div>

                <div style="width: 100%;height: 1px;background-color: gray"></div>

                <div v-if="showTargetItem"
                     style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin: 20px"
                     @click.stop="onHallJumpClick(showTargetItem)">
                    <label v-text="showTargetItem.gsmc" class="set-hall-label"></label>
                    <img src="../assets/ic_arrow_right.png" class="path-panel-close">
                </div>
            </div>
        </div>

        <div v-if="isShowLoading" style="width: 100%;height: 100%;position: fixed;top: 0;bottom: 0;z-index: 99999;background-color: rgba(0,0,0,0.5);
        display: flex;justify-content: center;align-items: center">

            <label class="loading-mask1">正在加载......</label>

        </div>

        <div v-if="isForDebug" style="position: fixed;margin: 0 0;font-size: 50px;color: black;top: 200px"
             v-text="this.pageRatio"></div>

    </div>
</template>

<script>

import Raphael from 'raphael';
import api from "@/utils/api";
import {getUrlParam, isDebug, isNull, printDir, printLog} from "@/utils/commonUtils";

const TEST_HALL_LIST = [
    {
        id: 1,
        name: "展馆1"
    },
    {
        id: 2,
        name: "展馆2"
    },
    {
        id: 3,
        name: "展馆3"
    },
    {
        id: 4,
        name: "展馆4"
    },
    {
        id: 5,
        name: "展馆5"
    }
]
const MAP_EDITOR_MAX_WIDTH = 1000

export default {
    data() {
        return {
            isForDebug: false,
            mapScale: 1,
            mapTransformX: 0,
            mapTransformY: 0,
            pageRatio: 100,
            isShowLoading: true,
            backHrefUrl: null,
            pageTitle: null,
            hallList: null,
            serverPointList: null,
            nearbyPointList: null,
            searchResultList: null,
            exhibitionId: 0,
            hallId: 0,
            mapHeight: 700,
            searchConditionType: 1,
            searchKeywords: null,
            searchPathObject: null,
            searchBoothObject: [],
            allBoothObject: [],
            mapEditorMaxWidth: MAP_EDITOR_MAX_WIDTH,
            canvasConfig: null,
            raphaelObj: null,
            routerPathIndex: 0,
            routerPointMap: [],
            china: [],
            pageTitleIndex: 0,
            showSearchPanel: false,
            isShowSearchPanelAnim: false,
            showSetStartAndEndPanel: false,
            showHallPanel: false,
            isShowHallPanelAnim: false,
            showTargetItem: null,
            startTargetItem: null,
            startTargetLabel: null,
            endTargetLabel: null,
            endTargetItem: null,
            startPositionId: null,
            endPositionId: null,
            mapData: {
                animConfig: {
                    duration: 3000,
                    attr: {
                        stroke: 'red',
                        strokeWidth: 4,
                        strokeOpacity: 1,
                        fill: 'none',
                        fillOpacity: 0.0
                    }
                },
                cellList: null,
                image: null,
                mouseOver: {
                    fillColor: "#35b5c0",
                    strokeColor: "#1eee32",
                    textColor: "#7511a8"
                },
                mouseOut: {
                    fillColor: "#97d6f5",
                    strokeColor: "#7a7979",
                    textColor: "#000000"
                },
            }
        }
    },
    mounted() {
        var query = window.location.href
        this.startPositionId = getUrlParam(query, 'start')
        this.endPositionId = getUrlParam(query, 'end')
        this.loadData(this.$route.params.eid, this.$route.params.hid)


        // this.watchPageZoom()
        this.addScrollListener()
    },
    methods: {
        addScrollListener() {
            let box = this.$refs.mapContainerWrap
            box.addEventListener("scroll", function () {
                printLog("====onScroll x:" + box.scrollLeft + ",y:" + box.scrollTop)
            })
        },
        getMapStyle() {
            return {
                transform: 'scale(' + this.mapScale + ')',
                // transform: 'scale(' + this.mapScale + ') translateX(' + this.mapTransformX + 'px) translateY(' + this.mapTransformY + 'px) ',
                'transform-origin': 'left',
            }
        },

        watchPageZoom() {
            if (this.isForDebug) {
                this.$nextTick(() => {
                    window.addEventListener("resize", () => {
                        // 监听浏览器窗口大小改变
                        // 浏览器变化执行动作
                        this.pageRatio = this.detectZoom();

                    });
                });
            }
        },


        detectZoom() {
            // let ratio = 0
            const screen = window.screen;
            const ua = navigator.userAgent.toLowerCase();
            if (window.devicePixelRatio !== undefined) {
                this.ratio = window.devicePixelRatio;
            } else if (~ua.indexOf("msie")) {
                if (screen.deviceXDPI && screen.logicalXDPI) {
                    this.ratio = screen.deviceXDPI / screen.logicalXDPI;
                }
            } else if (
                window.outerWidth !== undefined &&
                window.innerWidth !== undefined
            ) {
                this.ratio = window.outerWidth / window.innerWidth;
            }

            if (this.ratio) {
                this.ratio = Math.round(this.ratio * 100);
            }
            //得到的额百分比
            return this.ratio;
        },

        onZoomUp() {
            this.mapScale += 1;
            if (this.mapScale >= 5.0) {
                this.mapScale = 5.0
            }

        },
        onZoomDown() {
            this.mapScale -= 1;
            if (this.mapScale <= 1.0) {
                this.mapScale = 1.0
            }

        },

        toHallLocation(data) {
            // if (!this.isForDebug) {
            //     return
            // }
            if (data && data.path && data.path.data) {
                if (this.mapScale > 1) {
                    var positionList = data.path.data;
                    if (positionList.length > 2) {

                        var mapCenter = {
                            x: parseInt(this.getMapContainerWidth() * 0.5),
                            y: parseInt(this.mapHeight * 0.5)
                        }

                        var ratio = this.getMapPointRatio()
                        // var boxWidth = parseInt(Math.abs(positionList[0].x / ratio - positionList[1].x / ratio) * this.mapScale)
                        // var boxheight = parseInt(Math.abs(positionList[1].y / ratio - positionList[2].y / ratio) * this.mapScale)
                        var location = {x: positionList[0].x, y: positionList[0].y};
                        printLog("====To location，ratio:" + ratio + ",map center:" + mapCenter.x + "x" + mapCenter.y)
                        printDir(location)
                        var target = {
                            x: parseInt(location.x / ratio * this.mapScale - mapCenter.x),
                            y: parseInt(location.y / ratio * this.mapScale - mapCenter.y)
                        }
                        printDir(target)
                        this.scrollMapTo(target)
                    }
                }
            }
        },


        scrollMapTo(target) {
            var that = this
            this.$nextTick(() => {

                let box = that.$refs.mapContainerWrap
                box.scrollTo({
                    top: target.y,
                    left: target.x,
                    behavior: "smooth"
                })
            })
        },

        onBackClick() {
            if (window.history.length > 1) {
                window.history.back();
            } else {
                window.location.href = this.backHrefUrl ? this.backHrefUrl : "index.html";
            }
        },

        onCancelSetStartAndEndClick() {
            this.showSetStartAndEndPanel = false
            this.showTargetItem = null;
        },

        onCancelShowHallClick() {
            this.playCloseHallPanelAnim()
        },

        onSetStartAndEndPanelClick() {

        },


        onHallJumpClick(item) {
            if (item) {
                this.onResultLabelClick(item)
            }
            this.playCloseHallPanelAnim()
        },

        onSetStartHallClick() {
            if (this.showTargetItem) {
                this.startTargetItem = this.showTargetItem;
                this.startTargetLabel = this.startTargetItem.gsmc
            }
            this.showSetStartAndEndPanel = false
            this.playCloseHallPanelAnim()
        },
        onSetEndHallClick() {
            if (this.showTargetItem) {
                this.endTargetItem = this.showTargetItem;
                this.endTargetLabel = this.endTargetItem.gsmc
            }
            this.showSetStartAndEndPanel = false
            this.playCloseHallPanelAnim()
        },

        hasSearchResult() {
            return this.searchResultList && this.searchResultList.length > 0
        },

        hasServerPoint() {
            return this.serverPointList && this.serverPointList.length > 0
        },

        hasNearbyPoint() {
            return this.nearbyPointList && this.nearbyPointList.length > 0
        },

        onTargetLabelClick(item) {
            this.showTargetItem = item;
            this.showSetStartAndEndPanel = true
        },

        onResultLabelClick(item) {
            printDir(item)
            this.playCloseSearchPanelAnim()
            window.open(item.link, "_self")
        },

        onLocationClick(item) {
            printDir(item)
            this.highlightBooth(item)
            this.toHallLocation(item)
            this.playCloseSearchPanelAnim()
            this.playCloseHallPanelAnim()
        },

        playCloseSearchPanelAnim() {
            this.isShowSearchPanelAnim = false;
            setTimeout(() => {
                this.showSearchPanel = false
                this.showTargetItem = null;
                this.searchKeywords = null;
                this.pageTitleIndex = 0;
            }, 400)
        },

        playCloseHallPanelAnim() {
            this.isShowHallPanelAnim = false;
            setTimeout(() => {
                this.showHallPanel = false
                this.showTargetItem = null;
            }, 400)
        },

        onSearchPanelClick() {

        },

        onCancelSearchClick() {
            this.playCloseSearchPanelAnim()
        },

        onInputStartClick(index) {
            this.showSearchPanel = true;
            this.isShowSearchPanelAnim = true;
            this.startTargetItem = null;
            this.startTargetLabel = null;
            this.clearSearchPathObjects()
            this.clearSearchBoothObject()
            this.onPageTitleClick(this.pageTitleIndex)
        },

        onInputEndClick(index) {
            this.showSearchPanel = true;
            this.isShowSearchPanelAnim = true;
            this.endTargetItem = null;
            this.endTargetLabel = null;
            this.clearSearchPathObjects()
            this.clearSearchBoothObject()
            this.onPageTitleClick(this.pageTitleIndex)
        },

        onPageTitleClick(index) {
            this.pageTitleIndex = index
            if (index === 0) {
                this.searchResultList = this.mapData ? this.mapData.cellList : null;
            }
            this.scrollToPage(index);
        },

        loadData(eid, hid) {
            this.exhibitionId = eid
            this.hallId = hid
            printLog("Exhibition is " + this.exhibitionId + ",hallId is:" + this.hallId)
            this.getMapData()
        },

        onHallListClick() {

        },

        onHallListUpClick() {
            const index = this.findIdIndex(this.hallId);
            const list = this.hallList;
            const size = list ? list.length : 0;
            if (index > 0 && index < size) {
                this.onHallLabelClick(list[index - 1].id)
            }
            this.scrollToLabel(index - 1)
        },

        onHallListDownClick() {
            const index = this.findIdIndex(this.hallId);
            const list = this.hallList;
            const size = list ? list.length : 0;
            if (index > -1 && index < size - 1) {
                this.onHallLabelClick(list[index + 1].id)
            }
            this.scrollToLabel(index + 1)

        },

        scrollToLabel(index) {
            this.$nextTick(() => {
                const toTop = (index - 1) * 40;
                let box = this.$el.querySelector(".hall-list-container")
                box.scrollTo({
                    top: toTop,
                    behavior: "smooth"
                })
            })
        },

        scrollToPage(index) {
            this.$nextTick(() => {
                const toLeft = index * this.getMapContainerWidth();
                let box = this.$refs.searchPageView
                box.scrollTo({
                    left: toLeft,
                    behavior: "smooth"
                })
            })
        },

        findIdIndex(id) {
            const list = this.hallList
            const size = list ? list.length : 0;
            if (size > 0) {
                for (let i = 0; i < size; i++) {
                    const it = list[i];
                    if (it && it.id == id) {
                        return i
                    }
                }
            }
            return -1;
        },


        getMapContainerWidth() {
            return this.$refs.mapContainer.clientWidth;
        },

        getPageHeight() {
            return window.innerHeight;
        },

        getClickPoint(event) {
            const downX = event.offsetX
            const downY = event.offsetY
            // printLog("====map onMapClick:" + downX + "x" + downY + ",page width:" + pageSize.width + ",height:" + pageSize.height)
            // printDir(event)
            return {
                x: downX,
                y: downY
            }
        },

        onHallLabelClick(id) {
            printLog("onHallLabelClick:" + id)
            this.resetData()
            this.$router.replace({
                path: '/hallmap/' + this.exhibitionId + "/" + id
            })
            this.loadData(this.exhibitionId, id)
        },

        resetData() {
            this.clearSearchBoothObject()
            this.clearSearchPathObjects()
            this.clearAllBoothObject()
            this.mapScale = 1
            this.mapTransformX = 0
            this.mapTransformY = 0
            this.searchKeywords = null;
            this.showTargetItem = null;
            this.startTargetItem = null;
            this.endTargetItem = null;
            this.startTargetLabel = null;
            this.endTargetLabel = null;
            this.searchConditionType = 1;

            this.backHrefUrl = null
            this.mapData.cellList = null
            this.mapData.image = null
            this.canvasConfig.url = null

            this.serverPointList = null
            this.nearbyPointList = null
            this.searchResultList = null
            this.pageTitle = null
        },

        onSearchConditionClick() {
            this.clearSearchBoothObject()
            if (isNull(this.searchKeywords)) {
                alert("请输入搜索关键字")
                return
            }

            this.onPageTitleClick(0)
            this.isShowLoading = true
            api.get("/index.ashx?method=dg_zhanwei_mx&type=" + this.searchConditionType
                + "&cxtj=" + this.searchKeywords
                + "&zhid=" + this.exhibitionId
                + "&zhanguan=" + this.hallId).then(res => {
                this.searchResultList = res.zwList
                const boothIdList = res.zwList
                if (boothIdList && boothIdList.length > 0) {
                    // boothIdList.forEach((item) => {
                    //     if (item) {
                    //         const data = this.findBoothDataById(item)
                    //         if (data) {
                    //             this.searchBoothObject.push(this.highlightBooth(data));
                    //         }
                    //     }
                    // })
                } else {
                    alert("没有找到相关的信息")
                }
                this.isShowLoading = false
            }, error => {
                this.isShowLoading = false
            })
        },

        clearSearchBoothObject() {
            if (this.searchBoothObject.length > 0) {
                this.searchBoothObject.forEach((item) => {
                    if (item) {
                        item.remove()
                    }
                })
            }
        },

        clearAllBoothObject() {
            if (this.allBoothObject.length > 0) {
                this.allBoothObject.forEach((item) => {
                    if (item) {
                        item.remove()
                    }
                })
            }
        },

        findBoothDataById(id) {
            if (this.mapData && this.mapData.cellList) {
                const size = this.mapData.cellList.length;
                if (size > 0) {
                    for (var i = 0; i < size; i++) {
                        const cell = this.mapData.cellList[i];
                        if (id == cell.id && cell && cell.path && cell.path.data) {
                            return cell;
                        }
                    }
                }
            }
            return null
        },

        clearSearchPathObjects() {
            if (this.searchPathObject) {
                this.searchPathObject.remove();
            }
        },

        onSearchMapClick() {
            this.clearSearchPathObjects()

            if (isNull(this.startTargetItem)) {
                alert("请输入起点位置")
                return
            }

            if (isNull(this.endTargetItem)) {
                alert("请输入终点位置")
                return
            }
            // const startId = this.findHallId(this.startHall)
            // if (isNull(startId)) {
            //     alert("起点展位" + this.startHall + "不存在")
            //     return
            // }
            //
            // const endId = this.findHallId(this.endHall)
            // if (isNull(endId)) {
            //     alert("终点展位" + this.endHall + "不存在")
            //     return
            // }
            this.isShowLoading = true
            var that = this
            api.get("/index.ashx?method=dg_zw_line"
                + "&startHallId=" + this.startTargetItem.id
                + "&endHallId=" + this.endTargetItem.id
                + "&zhid=" + this.exhibitionId
                + "&zhanguan=" + this.hallId).then(res => {

                const pathObj = res
                if (pathObj && pathObj.pointList && pathObj.pointList.length > 1) {
                    that.mapScale = 1
                    that.scrollMapTo({x: 0, y: 0})
                    that.$nextTick(() => {
                        that.drawRouterPath(this.raphaelObj, pathObj.pointList)
                    })

                } else {
                    alert("没有找到合适的路线")
                }
                this.isShowLoading = false
            }, error => {
                this.isShowLoading = false
            })
        },


        onMapClick() {
            // this.$router.push({path: '/SearchHall'})
            // a:外部链接 ,b：打开方式（“_blank”新开一个窗口；“_self”覆盖当前窗口）
            // window.open("https://exhibitors.cioe.cn/jtycn/zs55775.html", "_self")
        },

        getMapData() {
            this.isShowLoading = true;
            api.get("/index.ashx?method=dg_zhanwei&zhid=" + this.exhibitionId + "&zhanguan=" + this.hallId).then(res => {
                let json = res;
                this.backHrefUrl = json.backHrefUrl
                this.mapData.cellList = json.cellList
                this.mapData.image = json.image
                this.canvasConfig = this.getCanvasWH(this.mapData.image);
                this.canvasConfig.url = this.mapData.image.url
                if (!this.raphaelObj) {
                    this.raphaelObj = Raphael("mapCanvas", this.canvasConfig.width, this.canvasConfig.height);
                }
                this.hallList = json.hallList
                this.serverPointList = json.fwdList
                this.nearbyPointList = json.fjList
                this.searchResultList = json.cellList;
                this.pageTitle = json.pageTitle;
                this.paintMap(this.mapData, this.canvasConfig);
                // this.pageTitle = "网页的标题"
                // this.hallList = TEST_HALL_LIST
                this.setPageTitle(this.pageTitle)
                this.findDefaultStartAndEnd()
                this.isShowLoading = false;
                this.setMapHeight()
                this.scrollMapTo({x: 0, y: 0})
            }, error => {
                this.isShowLoading = false;
            })
        },

        setMapHeight() {

            this.$nextTick(() => {
                let navBox = this.$refs.topNav
                let titleBox = this.$refs.topTitle
                let topHeight = navBox.clientHeight + titleBox.clientHeight;
                let pageHeight = this.getPageHeight()
                this.mapHeight = pageHeight - topHeight;
                printLog("=====page height:" + pageHeight + ",Top height:" + topHeight + ",map height:" + this.mapHeight)
            })


        },

        findDefaultStartAndEnd() {
            if (this.startPositionId || this.endPositionId) {
                var cellList = []
                if (this.mapData && this.mapData.cellList && this.mapData.cellList.length > 0) {
                    cellList = cellList.concat(this.mapData.cellList)
                }
                if (this.serverPointList && this.serverPointList.length > 0) {
                    cellList = cellList.concat(this.serverPointList)
                }
                if (this.nearbyPointList && this.nearbyPointList.length > 0) {
                    cellList = cellList.concat(this.nearbyPointList)
                }
                const size = cellList ? cellList.length : 0;
                if (size > 0) {
                    for (let i = 0; i < size; i++) {
                        const item = cellList[i];
                        if (item) {
                            const id = item.id;
                            if (!this.startTargetItem && id == this.startPositionId) {
                                this.startTargetLabel = item.gsmc
                                this.startTargetItem = {
                                    id: id,
                                    gsmc: item.gsmc
                                }
                                this.highlightBooth(item)
                            }
                            if (!this.endTargetItem && id == this.endPositionId) {
                                this.endTargetLabel = item.gsmc
                                this.endTargetItem = {
                                    id: id,
                                    gsmc: item.gsmc
                                }
                                this.highlightBooth(item)
                            }
                        }
                    }
                }
            }
            this.startPositionId = null
            this.endPositionId = null
        },

        setPageTitle(title) {
            this.$nextTick(() => {
                document.title = title ? title : "展馆地图";
            })
        },

        findHallId(name) {
            if (!isNull(name)) {
                if (this.mapData && this.mapData.cellList) {
                    const list = this.mapData.cellList;
                    if (list.length > 0) {
                        var cell = null
                        for (let i = 0; i < list.length; i++) {
                            cell = list[i]
                            if (cell && cell.title && !isNull(cell.title.label)) {
                                if (cell.title.label === name) {
                                    return cell.id
                                }
                            }
                        }
                    }
                }
            }
            return null
        },

        getPointString(point, appendL) {
            return point.x + "," + point.y + (appendL ? "L," : "")
        },

        getRectPath(ratio, pointArray, appendZ = true) {
            let pa = pointArray || []
            let len = pa.length
            let path = "";
            if (len > 0) {
                path += "M"
                pa.forEach((point, index) => {
                    const x = parseInt(point.x / ratio)
                    const y = parseInt(point.y / ratio)
                    path += x + "," + y + (index === len - 1 ? (appendZ ? "z" : "") : "L,")
                    pa.relPoint = {
                        x: x,
                        y: y
                    }
                })
            }
            printLog("Path:" + path)
            return path
        },

        getCanvasWH(imageConfig) {
            var canvasWidth = window.innerWidth
            const windowWidth = canvasWidth;
            const ratio = imageConfig.width / canvasWidth;
            const canvasHeight = parseInt(imageConfig.height / ratio)

            printLog("map width:" + canvasWidth + ",height:" + canvasHeight + ",ratio:" + ratio + ",windowWidth:" + windowWidth)
            return {
                width: canvasWidth,
                height: canvasHeight,
                ratio: ratio
            }
        },

        getMapPointRatio() {
            if (this.mapData && this.mapData.image) {
                var canvasWidth = window.innerWidth
                return this.mapData.image.width / canvasWidth;
            }
            return 1
        },

        highlightBooth(data) {
            if (data && data.path && data.path.data) {
                const pathAttr = {
                    "fill": "#FF0000",
                    "fill-opacity": 0.5,
                    "stroke": "#7a7979",
                    "stroke-width": 2,
                    "stroke-linejoin": "round"
                };
                const that = this
                const obj = this.raphaelObj.path(this.getRectPath(this.canvasConfig.ratio, data.path.data)).attr(pathAttr)
                obj[0].onclick = function () {
                    printLog("===Click highlightBooth ");
                    that.showTargetItem = data
                    that.onHallClick(data)
                };
                this.searchBoothObject.push(obj);
            }
        },

        paintMap(mapData, config) {
            const ratio = config.ratio
            const drawText = false
            var cellList = mapData.cellList
            if (this.nearbyPointList && this.nearbyPointList.length > 0) {
                cellList = cellList.concat(this.nearbyPointList)
            }
            if (this.serverPointList && this.serverPointList.length > 0) {
                cellList = cellList.concat(this.serverPointList)
            }
            var that = this;
            cellList.forEach((cell) => {
                const pathAttr = {
                    "fill": "#97d6f5",
                    "stroke": "#7a7979",
                    "stroke-width": 2,
                    "stroke-opacity": 0,
                    'fill-opacity': isDebug() ? 0.5 : 0,
                    "stroke-linejoin": "round"
                }
                const pathObj = this.raphaelObj.path(this.getRectPath(ratio, cell.path.data)).attr(pathAttr)
                that.allBoothObject.push(pathObj)
                //获取当前图形的中心坐标
                var xx = pathObj.getBBox().x + (pathObj.getBBox().width / 2);
                var yy = pathObj.getBBox().y + (pathObj.getBBox().height / 2);

                if (drawText) {
                    const textAttr = cell.title.textAttr || {
                        "fill": "#000000",
                        "font-size": "16px",
                        "cursor": "pointer"
                    }
                    this.raphaelObj.text(xx, yy, cell.title.label).attr(textAttr);
                }

                const pathView = pathObj[0]
                //
                // pathView.onmouseover = function () {
                //     let mouseOver = mapData.mouseOver
                //     if (mouseOver) {
                //         pathObj.animate({
                //             fill: mouseOver.fillColor,
                //             stroke: mouseOver.strokeColor
                //         }, 300);
                //         // textObj.animate({
                //         //     fill: mouseOver.textColor
                //         // })
                //         // textObj.toFront();
                //     }
                // };
                // pathView.onmouseout = function () {
                //     let mouseOut = mapData.mouseOut
                //     if (mouseOut) {
                //         pathObj.animate({
                //             fill: mouseOut.fillColor,
                //             stroke: mouseOut.strokeColor
                //         }, 300);
                //     }
                // };
                pathView.onclick = function () {
                    // printLog("===Click cell " + cell.title.label + ",id:" + cell.id);
                    // window.open(cell.link, "_self")
                    that.onHallClick(cell)
                };
            })
        },

        onHallClick(item) {
            this.showHallPanel = true;
            this.isShowHallPanelAnim = true;
            this.showTargetItem = item;
        },

        getPointRatio() {
            const mapContainerWidth = this.getMapContainerWidth();
            var ratio = mapContainerWidth / MAP_EDITOR_MAX_WIDTH;
            return ratio
        },

        getPointList(pointList) {
            const ratioList = []
            var ratio = this.getPointRatio()
            printLog("====ratio:" + ratio)
            pointList.forEach((item) => {
                ratioList.push({
                    x: parseInt(item.x * ratio),
                    y: parseInt(item.y * ratio),
                })
            });
            return ratioList
        },

        drawRouterPath(raphaelObj, pointList) {
            let animConfig = this.mapData.animConfig
            if (animConfig && animConfig.attr) {
                const list = this.getPointList(pointList)
                var path = "M"
                list.forEach((item) => {
                    path += this.getPointString(item, true)
                })

                printLog("=====new draw path is:" + path)
                this.searchPathObject = this.drawPath(raphaelObj,
                    path,
                    animConfig.duration,
                    {
                        stroke: animConfig.attr.stroke,
                        'stroke-width': animConfig.attr.strokeWidth,
                        'stroke-opacity': 1,
                        fill: animConfig.attr.fill,
                        'fill-opacity': animConfig.attr.fillOpacity,
                        "stroke-linejoin": "round"
                    },
                    null);
            }
        },


        drawPath(canvas, pathstr, duration, attr, callback) {
            const guide_path = canvas.path(pathstr).attr({stroke: "none", fill: "none"});
            const path = canvas.path(guide_path.getSubpath(0, 1)).attr(attr);
            const total_length = guide_path.getTotalLength(guide_path);
            // const last_point = guide_path.getPointAtLength(0);
            const start_time = new Date().getTime();
            const interval_length = 50;
            const result = path;

            var interval_id = setInterval(function () {
                var elapsed_time = new Date().getTime() - start_time;
                var this_length = elapsed_time / duration * total_length;
                attr.path = guide_path.getSubpath(0, this_length);

                path.animate(attr, interval_length);
                if (elapsed_time >= duration) {
                    clearInterval(interval_id);
                    guide_path.remove();
                    // path.remove();
                    if (callback) {
                        callback();
                    }
                }
            }, interval_length);
            return result;
        }
        ,

    },


}


</script>

<style rel="stylesheet/scss" lang="scss">

.set-hall-label {
  font-size: 34px;
  color: black;
}

.set-hall-button {
  margin: 0 20px;
  border: transparent;
  font-size: 30px;
  color: white;
  background: #307FD1;
  border-radius: 10px;
  padding: 6px 16px;
}

.search-result-label {
  flex: 1;
  font-size: 30px;
  color: black;
  padding: 20px 0;
  vertical-align: middle;
}

.search-result-icon {
  width: 60px;
  height: 40px;
  object-fit: contain;
}

.no-content-page {
  width: 100%;
  height: 100%;
  line-height: 300px;
  text-align: center;
  font-size: 34px;
  vertical-align: middle;
}

.page-item {
  flex: 0 0 100%;
  height: 100%;
  position: relative;
}

.page-title {
  display: inline-block;
  font-size: 34px;
  text-align: center;
  margin: 2px;
  line-height: 70px;
  width: 160px;
  vertical-align: middle;
  height: 70px;
  color: black;
  border-bottom: white solid 6px;
}

.page-title-sel {
  border-bottom: #307FD1 solid 6px;
  color: #307FD1;
  background-color: #cfdcea;
}

.path-panel-close {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.path-title-label {
  color: black;
  padding: 16px;
  font-size: 40px;
}

.condition-radio1 {
  width: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: 40px;
  height: 40px;
}

input[type="radio" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 0 6px;
  padding: initial;
  border: initial;
}

.condition-radio-label1 {
  font-size: 32px;
  color: black;
  width: 80px;
}

.navigator-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 0
}

.navigator-list {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
}

.search-input-view1 {
  padding: 20px;
  width: 100%;
  border-radius: 18px;
  border: 1px solid #D2D2D1;
  font-size: 34px;
}

.gap-view {
  width: 14px;
}

.search-input-button1 {
  font-size: 28px;
  padding: 12px 30px;
  border-radius: 18px;
  border: transparent;
  background-color: #307FD1;
  color: white;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
}

.search-input-button2 {
  font-size: 36px;
  padding: 12px 0;
  width: 120px;
  border-radius: 18px;
  border: transparent;
  background-color: #307FD1;
  color: white;
  display: inline-flex;
  margin: 0 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
}

.search-condition-input1 {
  font-size: 34px;
  border-radius: 10px;
  width: calc(100% - 200px);
  box-sizing: content-box;
  padding: 20px;
  border: 1px solid #D2D2D1;
}

.search-condition-button {
  background-color: transparent;
  border: transparent;
  outline: none;
  font-size: 26px;
  color: #307FD1
}

.hall-list-panel-wrap {
  background-color: rgba(124, 120, 120, 0.5);
  position: fixed;
  border-radius: 40px;
  right: 20px;
  bottom: 170px;
}

.hall-list-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap
}

.hall-list-arrow {
  width: 50px;
  height: 50px;
}

.navigator-size {
  width: 40px;
  height: 40px;
}

.navigator-label {
  font-size: 30px;
  margin-left: 6px;
}

.hall-list-container {
  height: 240px;
  overflow-y: scroll
}

/* 隐藏滚动条 */

::-webkit-scrollbar {

  width: 0;

  height: 0;

  background-color: transparent;

  /* 隐藏滚动条轨道 */

  ::-webkit-scrollbar-track {

    background-color: transparent;

    /* 隐藏滚动条滑块 */

    ::-webkit-scrollbar-thumb {

      background-color: transparent;
    }
  }
}

.hall-list-label {
  display: block;
  margin: 15px 0;
  font-size: 20px;
  background-color: #98bee5;
  border-radius: 10px;
  padding: 15px 20px;
  color: white
}

.hall-list-label-sel {
  background-color: #307FD1;
}

.pop-in-from-bottom {
  animation: slideContentUp 0.3s ease-in-out both;
}

.pop-out-from-top {
  animation: slideContentDown 0.3s ease-out both;
}

@keyframes slideContentUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideContentDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.page-top-title {
  width: 100%;
  height: 80px;
  font-size: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
  color: black;
}

.back-button1 {
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 30px;
  text-align: center;
  line-height: 90px;
  vertical-align: middle;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #307FD1;
  color: white;
}

.back-button3 {
  font-size: 30px;
  text-align: center;
  line-height: 70px;
  vertical-align: middle;
  width: 70px;
  border-radius: 10px;
  height: 70px;
}

.loading-mask1 {
  font-size: 40px;
  color: white;
}

.zoom-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 18px 0
}
</style>