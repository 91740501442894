import axios from 'axios'

const BasePath = '/zhanwei';

// 创建axios实例
const axiosInstance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    withCredentials: true,          // 跨域设置
    timeout: 30000                  // 请求超时时间2
})
axiosInstance.interceptors.request.use(config => {
    if (!config.url.startsWith(BasePath)) {
        config.url = BasePath + config.url
    }
    return config;
}, error => {
    // Do something with request error
    console.error(error) // for debug
    Promise.reject(error)
})
axiosInstance.interceptors.response.use(
    response => {
        const res = response.data;
        if (res && (res.code === 200)) {
            return res.data;
        }
        return Promise.reject(res)
    },
    error => {
        console.error('error:' + error)// for debug
        return Promise.reject(error)
    }
)

export default axiosInstance

