<template>

    <div style="width: 100%;height: 400px;background-color: red">
        <label style="color: red;background-color: aqua">搜索展馆页面</label>
    </div>
</template>

<script>
export default {
    name: "SearchHall"
}
</script>

<style scoped>

</style>