<template>


    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;
   padding: 0;margin: 0" @keyup.ctrl.esc="onRevokePointAction">

        <div ref="mapContainer" style="position: relative;"
             :style="'width:'+(this.isEditorMode?this.mapEditorMaxWidth+'px':'100%')">
            <img v-if="this.canvasConfig" :src="this.canvasConfig.url"
                 style="object-fit: contain;width: 100%;height: auto;position: absolute">
            <div id="mapCanvas" @click="onMapClick"
                 @contextmenu.prevent.stop="onMapRightClick($event)"
                 style="position: absolute;margin: auto;display: flex;justify-content: center;align-items: center;width: 100%">

            </div>


        </div>

        <div v-if="this.isEditorMode" style="position: fixed;top: 50px;right: 2px;overflow: hidden">

            <div style="background-color: gray;height: 30px;display: flex;flex-direction: row;align-items: center;">
                <input type="checkbox" name="autoAlign" v-model="isShowLine"
                       @change="onShowLineChange"
                       style="color: white;width: 20px;height: 20px">
                <label style="color: white;font-size: 20px">显示所有线路</label>
            </div>

            <div style="background-color: gray;height: 30px;display: flex;flex-direction: row;align-items: center;">
                <input type="checkbox" name="autoAlign" v-model="isAutoAlign"
                       style="color: white;width: 20px;height: 20px">
                <label style="color: white;font-size: 20px">自动对齐</label>
            </div>
            <div
                @click.stop=""
                id="path-label-list"
                style="width: 240px;background-color: rgba(128,128,128,0.5);overflow-y:scroll;height: 800px"></div>
        </div>


        <div v-if="this.showAddHallIdView"
             @click.prevent.stop="onCancelAddHallClick()"
             style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background-color: rgba(0,0,0,0.5);display: flex;flex-direction: column;justify-content: center;align-items: center">

            <div
                @click.prevent.stop="onAddHallViewClick()"
                style="margin-top: 20px;display: flex;flex-direction: row;align-items: center;justify-content: center;
                    background-color: white;height: 100px;padding: 0 30px">
                <input style=" padding: 6px;border-radius: 4px;font-size: 26px;width: 220px;margin-right: 10px;"
                       type="text" placeholder="起点展位" v-model="startHall">
                <input style=" padding: 6px;border-radius: 4px;font-size: 26px;width: 220px;margin-right: 10px;"
                       type="text" placeholder="终点展位" v-model="endHall">
                <button style="font-size: 24px;padding: 4px 20px;border-radius: 4px" @click="onAddPathClick">
                    添加
                </button>
            </div>

        </div>

        <div v-text="this.toastContent" v-if="showToast" :style="{backgroundColor:this.toastBackgroundColor}"
             style="position: fixed;top: 6px;font-size: 20px;color: white;padding: 6px 20px;border-radius: 4px"></div>


        <div v-if="isShowLoading" style="width: 100%;height: 100%;position: fixed;top: 0;bottom: 0;z-index: 99999;background-color: rgba(0,0,0,0.5);
        display: flex;justify-content: center;align-items: center">

            <label class="loading-mask">正在加载......</label>

        </div>
    </div>


</template>

<script>

import Raphael from 'raphael';
import api from "@/utils/api";
import {isDebug, isNull, printDir, printLog} from "@/utils/commonUtils";

const pointThreshold = 4;
const LINE_POINT_SIZE = 10
const MAP_EDITOR_MAX_WIDTH = 1000
const PATH_COLORS = [
    "#00FF00",
    "#0000FF",
    "#B71C1C",
    "#4A148C",
    "#006064",
    "#1A237E",
    "#827717",
    "#0D47A1",
    "#FF6F00",
    "#01579B",
    "#004D40",
    "#212121",
    "#1B5E20",
    "#BF360C",
    "#33691E",
    "#880E4F",
    "#311B92",
    "#F57F17",
    "#E65100",
    "#3E2723",
    "#263238",
]

export default {
    name: 'china_map',
    data() {
        return {
            isShowLoading: true,
            exhibitionId: 0,
            hallId: 0,
            isAutoAlign: true,
            isShowLine: true,
            isEditorMode: true,
            showToast: false,
            toastBackgroundColor: "#FFFFFF",
            toastContent: "",
            toastDelayTimeId: -1,
            showAddHallIdView: false,
            startHall: null,
            endHall: null,
            mapEditorMaxWidth: MAP_EDITOR_MAX_WIDTH,
            canvasConfig: null,
            raphaelObj: null,
            routerPathIndex: -1,
            routerPointMap: [],
            allPathLabelDivList: [],
            allHallObj: [],
            mapBackground: "#00ff00",
            deleteIconSrc: require("../assets/ic_delete.png"),
            mapData: {
                animConfig: {
                    duration: 3000,
                    attr: {
                        stroke: 'red',
                        strokeWidth: 10,
                        strokeOpacity: 1,
                        fill: 'none',
                        fillOpacity: 0.0
                    }
                },
                fjList: null,
                fwdList: null,
                cellList: null,
                image: null,
                mouseOver: {
                    fillColor: "#35b5c0",
                    strokeColor: "#1eee32",
                    textColor: "#7511a8"
                },
                mouseOut: {
                    fillColor: "#97d6f5",
                    strokeColor: "#7a7979",
                    textColor: "#000000"
                },
            }
        }
    },
    mounted() {
        this.exhibitionId = this.$route.params.eid
        this.hallId = this.$route.params.hid
        printLog("Exhibition is " + this.exhibitionId + ",hallId is:" + this.hallId)
        this.loadData()
        window.addEventListener(
            "keyup", event => {
                //todo
                event.key
                if (event.ctrlKey && event.keyCode !== 17) {
                    printLog('Ctrl + ' + event.key);
                    this.onKeyCtrlAndZPressed()
                }
            }
        );
    },
    methods: {

        loadData() {
            this.clearAllHallObj()
            this.cleanAllDotAndPath()
            this.startHall = null;
            this.endHall = null;
            this.routerPathIndex = -1;
            this.routerPointMap.length = 0;
            this.initPathObject()
            this.getMapData()
        },

        clearAllHallObj() {
            if (this.allHallObj) {
                this.allHallObj.forEach((item) => {
                    item.remove()
                })
            }
        },

        onShowLineChange(e) {
            this.isShowLine = e.target.checked
            printLog("===checked?" + this.isShowLine)
            this.loadData()
        },

        onKeyCtrlAndZPressed() {
            printLog("onKeyCtrlAndZPressed")
            this.deleteMarker()
        },

        getMapContainerWidth() {
            return this.$refs.mapContainer.clientWidth;
        },

        onCancelAddHallClick() {
            this.showAddHallIdView = false
        },

        onAddPathClick() {

            if (isNull(this.startHall)) {
                alert("请输入起点展位")
                return
            }

            if (isNull(this.endHall)) {
                alert("请输入终点展位")
                return
            }
            const startId = this.findHallId(this.startHall)
            if (isNull(startId)) {
                alert("起点展位" + this.startHall + "不存在")
                return
            }

            const endId = this.findHallId(this.endHall)
            if (isNull(endId)) {
                alert("终点展位" + this.endHall + "不存在")
                return
            }

            let routerPointMapObj = this.routerPointMap[this.routerPathIndex];
            this.addPathRequest(routerPointMapObj.id, startId, endId, routerPointMapObj.pointList)

        },

        showSuccessToast(message) {
            this.showToast = true;
            this.toastContent = message;
            this.toastBackgroundColor = "#00ff00"
            this.delayCloseToast(1500)
        },

        showErrorToast(message) {
            this.showToast = true;
            this.toastContent = message;
            this.toastBackgroundColor = "#ff0000"
            this.delayCloseToast(1500)
        },

        delayCloseToast(delayTime) {
            if (this.toastDelayTimeId !== -1) {
                clearTimeout(this.toastDelayTimeId)
            }
            this.toastDelayTimeId = setTimeout(() => {
                this.showToast = false
            }, delayTime);
        },

        findHallId(name) {
            if (!isNull(name)) {
                if (this.mapData) {
                    var result = this.findFromList(name, this.mapData.cellList)
                    if (!result) {
                        result = this.findFromList(name, this.mapData.fjList)
                    }
                    if (!result) {
                        result = this.findFromList(name, this.mapData.fwdList)
                    }
                    return result
                }
            }
            return null
        },

        findFromList(name, list) {
            if (list && list.length > 0) {
                var cell = null
                for (let i = 0; i < list.length; i++) {
                    cell = list[i]
                    if (cell && cell.title && !isNull(cell.title.label)) {
                        if (cell.title.label == name) {
                            return cell.id
                        }
                    }
                }
            }
            return null
        },

        onAddHallViewClick() {
            //Do nothing
        },

        getClickPoint(event) {
            const downX = event.offsetX
            const downY = event.offsetY
            // printLog("====map onMapClick:" + downX + "x" + downY + ",page width:" + pageSize.width + ",height:" + pageSize.height)
            // printDir(event)
            return {
                x: downX,
                y: downY
            }
        },

        onRevokePointAction() {
            printLog("onRevokePointAction")
        },

        onMapRightClick() {
            if (this.isEditorMode) {
                let routerPointMapObj = this.routerPointMap[this.routerPathIndex];
                if (routerPointMapObj) {
                    if (routerPointMapObj.pointList && routerPointMapObj.pointList.length > 1) {
                        this.showAddHallIdView = true;
                    }
                }
            }
        },

        findHallName(id) {
            var name = ""
            if (this.mapData) {
                if (this.mapData.cellList) {
                    name = this.findIdFromList(id, this.mapData.cellList)
                }

                if (isNull(name)) {
                    if (this.mapData.fjList) {
                        name = this.findIdFromList(id, this.mapData.fjList)
                    }
                }

                if (isNull(name)) {
                    if (this.mapData.fwdList) {
                        name = this.findIdFromList(id, this.mapData.fwdList)
                    }
                }
            }
            return name;
        },


        findIdFromList(id, list) {
            if (list && id) {
                var size = list.length;
                if (size > 0) {
                    for (let i = 0; i < size; i++) {
                        var hall = list[i]
                        if (hall && hall.id == id) {
                            if (!isNull(hall.gsmc)) {
                                return hall.gsmc
                            }
                            if (hall.title && hall.title.label) {
                                return hall.title.label
                            }
                        }
                    }
                }
            }
            return null;
        },

        onMouseEnterPath(id) {

        },

        onMouseLeavePath(id) {

        },

        addNewPathLabel(startId, endId) {
            const div = document.createElement('div')
            var startName = this.findHallName(startId)
            var endName = this.findHallName(endId)
            let routerPointMapObj = this.routerPointMap[this.routerPathIndex];

            div.className = 'path-label'
            div.id = routerPointMapObj.pathIndex
            div.style.width = '100%'
            div.style.height = '30px'
            div.style.marginTop = "4px"
            div.style.position = "relative"
            div.style.marginBottom = "10px"
            div.style.borderRadius = '8px'
            div.style.backgroundColor = routerPointMapObj.pathColor
            div.textContent = startName + "--" + endName
            div.style.color = "#ffffff"
            div.style.fontSize = '10px'
            div.style.textAlign = 'center'
            this.allPathLabelDivList.push(div)
            var that = this
            div.onmouseenter = function () {
                printLog(startName + " On =====Hover")
                that.onMouseLeavePath(routerPointMapObj.pathIndex)
            }
            div.onmouseleave = function () {
                printLog(startName + " On =====Out")
                that.onMouseEnterPath(routerPointMapObj.pathIndex)
            }
            document.getElementById('path-label-list').appendChild(div)

            div.onmouseover = function () {

            };

            div.onmouseout = function () {

            };

            const close = document.createElement('img')
            close.className = 'path-label-close'
            close.id = "delete-label-close"
            close.style.height = '30px'
            close.style.height = '30px'
            close.style.position = 'absolute'
            // close.style.display = 'fixed'
            close.style.right = '0'
            close.style.top = '0'
            close.style.color = "#000000"
            close.src = this.deleteIconSrc
            close.onclick = function (event) {

                const index = div.id
                const pathObj = that.routerPointMap[index];
                event.stopImmediatePropagation()
                if (pathObj) {
                    that.deletePathRequest(pathObj.id, div, pathObj)
                }
            };
            div.appendChild(close)
            printDir(this.raphaelObj)
            //TODO 这里有问题
            // this.raphaelObj.top.remove()
            // routerPointMapObj.pointList.forEach((point) => {
            //     this.addMarker(point)
            // })
            this.routerPointMap[this.routerPathIndex].pointPathDiv = this.raphaelObj.top

            this.initPathObject()
        },

        onMapClick(event) {
            if (this.isEditorMode) {
                const point = this.getClickPoint(event);
                this.addMarker(point, true)
            }
        },

        getAlignPoint(srcPoint) {
            if (this.isAutoAlign) {
                let routerPointMapObj = this.routerPointMap[this.routerPathIndex];
                if (routerPointMapObj) {
                    const pointList = routerPointMapObj.pointList;
                    const size = pointList ? pointList.length : 0;
                    if (size > 0) {
                        const lastPoint = pointList[size - 1];
                        if (lastPoint) {
                            if (Math.abs(lastPoint.x - srcPoint.x) > Math.abs(lastPoint.y - srcPoint.y)) {
                                return {
                                    x: srcPoint.x,
                                    y: lastPoint.y
                                };
                            } else {
                                return {
                                    x: lastPoint.x,
                                    y: srcPoint.y,
                                }
                            }
                        }
                    }
                }
            }
            return srcPoint;
        },

        getClosestPoint(clickPoint) {
            const size = this.routerPointMap.length;
            if (size > 0) {
                for (let i = 0; i < size; i++) {
                    if (i === (this.routerPathIndex)) {
                        //不能与自己的path相交
                        continue
                    }
                    const obj = this.routerPointMap[i];
                    if (obj && obj.isDelete === false) {
                        const routerPoint = this.routerPointMap[i].pointList;
                        if (routerPoint) {
                            const pointCount = routerPoint.length;
                            if (pointCount > 0) {
                                for (let i = 0; i < pointCount; i++) {
                                    const oldPoint = routerPoint[i];
                                    if (oldPoint != null) {
                                        const radius = this.getPointsDistance(clickPoint, oldPoint)
                                        if (radius < pointThreshold) {
                                            return {
                                                x: oldPoint.x,
                                                y: oldPoint.y
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return clickPoint;
        },

        getPointsDistance(pointStart, pointEnd) {
            return Math.sqrt(
                Math.pow(Math.abs(pointStart.x - pointEnd.x), 2)
                + Math.pow(Math.abs(pointStart.y - pointEnd.y), 2)
            )
        },

        getRouterPointList() {
            var obj = this.routerPointMap[this.routerPathIndex];
            if (!obj) {
                this.initPathObject()
            }
            obj = this.routerPointMap[this.routerPathIndex]
            return obj.pointList;
        },

        initPathObject(id = null) {
            this.routerPathIndex++;
            this.routerPointMap.push({
                pathIndex: this.routerPathIndex,
                pointList: [],
                markList: [],
                pointPathDiv: null,
                isDelete: false,
                id: id ? id : new Date().getTime(),
                pathColor: this.getRandomHexColor1()
            })
        },

        deleteMarker() {
            const pathObj = this.routerPointMap[this.routerPathIndex]
            const routerPoint = pathObj.pointList
            const size = routerPoint ? routerPoint.length : 0
            if (size > 0) {
                routerPoint.pop();
                var node = pathObj.markList.pop()
                if (node) {
                    document.getElementById('mapCanvas').removeChild(node)
                }
                if (size > 1) {
                    this.drawPointPath(pathObj, true);
                } else {
                    this.deleteLastPath(pathObj.pathIndex)
                }
            }
        },

        cleanAllDotAndPath() {
            if (this.routerPointMap && this.routerPointMap.length > 0) {
                this.routerPointMap.forEach((item) => {
                    if (item) {
                        this.deletePathAndDotOnMap(item)
                    }
                })

            }

            if (this.allPathLabelDivList) {
                this.allPathLabelDivList.forEach((item) => {
                    if (item) {
                        document.getElementById('path-label-list').removeChild(item);
                    }
                })
                this.allPathLabelDivList.length = 0
            }

        },

        addMarker(clickPoint, isClick = false) {
            const pathObj = this.routerPointMap[this.routerPathIndex]
            const routerPoint = pathObj.pointList
            const srcPoint = isClick && this.isAutoAlign ? clickPoint : this.getClosestPoint(clickPoint);
            const point = isClick ? this.getAlignPoint(srcPoint) : srcPoint
            point.groupIndex = this.routerPathIndex
            routerPoint.push(point);
            this.createPointDot(pathObj, point, clickPoint, isClick)
            this.drawPointPath(pathObj, isClick)
        },

        createPointDot(pathObj, point, clickPoint, isClick = false) {
            const radius = parseInt(LINE_POINT_SIZE * 0.5)
            const div = document.createElement('div')
            div.id = pathObj.pathIndex
            div.className = 'marker'
            div.style.position = 'absolute'
            div.style.width = LINE_POINT_SIZE + 'px'
            div.style.height = LINE_POINT_SIZE + 'px'
            div.style.backgroundColor = isClick ? "#ff0000" : (this.isShowLine ? "#eccccc" : "")
            div.style.borderRadius = '50%'
            div.style.left = (point.x - radius) + 'px'
            div.style.top = (point.y - radius) + 'px'
            const that = this;
            div.onclick = function (event) {
                //点击到了其他path的点，可能会有问题
                event.stopImmediatePropagation()
                const routerPoint = that.getRouterPointList()
                if (that.isSamePoint(that.getLastPoint(routerPoint), clickPoint)) {
                    return
                }
                const alignPoint = that.getAlignPoint(clickPoint)
                routerPoint.push(alignPoint);
                if (that.isAutoAlign) {
                    that.createPointDot(pathObj, alignPoint, clickPoint, true)
                }
                that.drawPointPath(that.routerPointMap[that.routerPathIndex], true);
            }
            pathObj.markList.push(div)
            document.getElementById('mapCanvas').appendChild(div)
        },

        getLastPoint(list) {
            if (list && list.length > 0) {
                return list[list.length - 1]
            }
            return null
        },

        isSamePoint(aPoint, bPoint) {
            if (aPoint && bPoint) {
                if (aPoint.x === bPoint.x && aPoint.y === bPoint.y) {
                    return true;
                }
            }
            return false;
        },

        drawPointPath(pathObj, isClick = false) {
            var pointCount = pathObj.pointList.length;
            this.deleteLastPath(pathObj.pathIndex)
            if (pointCount > 1) {
                const pathAttr = {
                    // "fill": "#97d6f5",
                    "stroke": pathObj.pathColor,
                    "stroke-width": LINE_POINT_SIZE,
                    "stroke-opacity": isClick ? 1.0 : (this.isShowLine ? 0.4 : 0.0),
                    "stroke-linejoin": "round"
                }
                var path = "M"
                pathObj.pointList.forEach((point) => {
                    path += point.x + "," + point.y + "L,"
                });

                const p = this.raphaelObj.path(path).attr(pathAttr)
                p.id = pathObj.pathIndex
                printLog("draw router path is:" + path + ",color:" + pathObj.pathColor)
            }
        },

        deleteLastPath(pathIndex) {


            const topObj = this.raphaelObj.top
            if (topObj) {
                if (topObj.id === pathIndex) {
                    printLog("delete id " + topObj.id)
                    topObj.remove()
                }
            }
        },

        requestAllPath() {
            this.isShowLoading = true;
            api.get("/index.ashx?method=dg_zwline"
                + "&zhid=" + this.exhibitionId
                + "&zhanguan=" + this.hallId).then(res => {
                printLog("requestAllPath ok")
                printDir(res.pathList)
                var hasOld = false;
                if (res && res.pathList) {
                    const size = res.pathList.length;
                    if (size > 0) {
                        for (var i = 0; i < size; i++) {
                            const pathObj = res.pathList[i];
                            if (pathObj && pathObj.pointList && pathObj.pointList.length > 1) {
                                hasOld = true
                                this.initPathObject(pathObj.id)
                                pathObj.pointList.forEach((point) => {
                                    this.addMarker(point)
                                })
                                this.addNewPathLabel(pathObj.startHallId, pathObj.endHallId)
                            }
                        }
                    }
                }
                if (hasOld) {
                    this.initPathObject()
                }
                this.isShowLoading = false;
            }, error => {
                this.isShowLoading = false;
            })
        },

        getMapData() {
            this.isShowLoading = true;
            api.get("/index.ashx?method=dg_zhanwei&zhid=" + this.exhibitionId + "&zhanguan=" + this.hallId)
                .then(res => {

                    printDir(res)
                    let json = res;
                    this.mapBackground = "#DDDDDD"
                    this.mapData.cellList = json.cellList
                    this.mapData.fjList = json.fjList
                    this.mapData.fwdList = json.fwdList
                    this.mapData.image = json.image
                    this.canvasConfig = this.getCanvasWH(this.mapData.image);
                    this.canvasConfig.url = this.mapData.image.url
                    if (!this.raphaelObj) {
                        this.raphaelObj = Raphael("mapCanvas", this.canvasConfig.width, this.canvasConfig.height);
                    }
                    this.paintMap(this.mapData, this.canvasConfig);
                    this.requestAllPath()
                }, error => {
                    this.isShowLoading = false;
                    alert("请求出错:" + error.message)
                })
        }
        ,

        addPathRequest(pathId, startHallId, endHallId, pointList) {
            const data = {
                id: pathId,
                startHallId: startHallId,
                endHallId: endHallId,
                pointList: pointList,
                zhid: this.exhibitionId,
                zhanguan: this.hallId,
                mapId: this.hallId
            }
            printLog("request data")
            printDir(data)
            api.post("/index.ashx?method=add_zw_line", data, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                // this.startHall = null
                this.endHall = null
                this.showAddHallIdView = false
                this.addNewPathLabel(startHallId, endHallId)
                this.showSuccessToast("添加成功")
            })
        },

        deletePathRequest(pathId, div, pathObj) {
            api.get("/index.ashx?method=del_zw_line&zhid=" + this.exhibitionId + "&zhanguan=" + this.hallId + "&id=" + pathId).then(res => {
                printLog("deletePathRequest ok")
                printDir(res)
                document.getElementById('path-label-list').removeChild(div)
                // this.allPathLabelDivList.remove(div)
                this.deletePathAndDotOnMap(pathObj)
                this.showSuccessToast("删除成功")
            })
        },

        deletePathAndDotOnMap(pathObj) {
            const divObj = pathObj.pointPathDiv;
            if (divObj) {
                divObj.isDelete = true
                divObj.remove()
                pathObj.markList.forEach((div) => {
                    document.getElementById('mapCanvas').removeChild(div)
                })
            }
        },

        getRandomHexColor() {//十六进制颜色随机
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
        },

        getRandomHexColor1() {//十六进制颜色随机
            let length = PATH_COLORS.length;
            const index = this.routerPathIndex % length
            printLog("=====color index:" + index + "，length:" + length + "path index：" + this.routerPathIndex)
            return PATH_COLORS[index]
        },


        getPointString(point, appendL) {
            return point.x + "," + point.y + (appendL ? "L," : "")
        },

        getRectPath(ratio, pointArray, appendZ = true) {
            let pa = pointArray || []
            let len = pa.length
            let path = "";
            if (len > 0) {
                path += "M"
                pa.forEach((point, index) => {
                    const x = parseInt(point.x / ratio)
                    const y = parseInt(point.y / ratio)
                    path += x + "," + y + (index === len - 1 ? (appendZ ? "z" : "") : "L,")
                    pa.relPoint = {
                        x: x,
                        y: y
                    }
                })
            }
            printLog("Path:" + path)
            return path
        },

        getCanvasWH(imageConfig) {
            var canvasWidth = window.innerWidth
            const windowWidth = canvasWidth;
            if (this.isEditorMode) {
                if (canvasWidth > MAP_EDITOR_MAX_WIDTH) {
                    canvasWidth = MAP_EDITOR_MAX_WIDTH;
                }
            }
            const ratio = imageConfig.width / canvasWidth;
            const canvasHeight = parseInt(imageConfig.height / ratio)

            printLog("map width:" + canvasWidth + ",height:" + canvasHeight + ",ratio:" + ratio + ",windowWidth:" + windowWidth)
            return {
                width: canvasWidth,
                height: canvasHeight,
                ratio: ratio
            }
        },

        paintMap(mapData, config) {

            // const canvasWidth = config.width;
            // const canvasHeight = config.height;
            const ratio = config.ratio

            const drawText = false

            var cellList = mapData.cellList
            if (mapData.fjList && mapData.fjList.length > 0) {
                cellList = cellList.concat(mapData.fjList)
            }
            if (mapData.fwdList && mapData.fwdList.length > 0) {
                cellList = cellList.concat(mapData.fwdList)
            }
            // this.raphaelObj.image(config.url, 0, 0, canvasWidth, canvasHeight);
            cellList.forEach((cell) => {
                const pathAttr = {
                    "fill": "#97d6f5",
                    "stroke": "#7a7979",
                    "stroke-width": 2,
                    "stroke-opacity": 0,
                    'fill-opacity': isDebug() ? 0.4 : 0,
                    "stroke-linejoin": "round"
                }
                const pathObj = this.raphaelObj.path(this.getRectPath(ratio, cell.path.data)).attr(pathAttr)

                //获取当前图形的中心坐标
                var xx = pathObj.getBBox().x + (pathObj.getBBox().width / 2);
                var yy = pathObj.getBBox().y + (pathObj.getBBox().height / 2);

                cell.relCenterPoint = {
                    x: xx, y: yy
                }

                if (drawText) {
                    const textAttr = cell.title.textAttr || {
                        "fill": "#000000",
                        "font-size": "16px",
                        "cursor": "pointer"
                    }
                    this.raphaelObj.text(xx, yy, cell.title.label).attr(textAttr);
                }

                const pathView = pathObj[0]

                pathView.onmouseover = function () {
                    let mouseOver = mapData.mouseOver
                    if (mouseOver) {
                        pathObj.animate({
                            fill: mouseOver.fillColor,
                            stroke: mouseOver.strokeColor
                        }, 300);
                        // textObj.animate({
                        //     fill: mouseOver.textColor
                        // })
                        // textObj.toFront();
                    }
                };
                pathView.onmouseout = function () {
                    let mouseOut = mapData.mouseOut
                    if (mouseOut) {
                        pathObj.animate({
                            fill: mouseOut.fillColor,
                            stroke: mouseOut.strokeColor
                        }, 300);
                        // textObj.animate({
                        //     fill: mouseOut.textColor
                        // })
                        // textObj.toFront();
                    }
                };
                pathView.onclick = function () {
                    printLog("===Click cell " + cell.title.label + ",id:" + cell.id);
                };


                this.allHallObj.push(pathView)
            });

            // this.drawTestAnim(raphaelObj)

            // this.drawRouterPath(this.raphaelObj)
        },

        getPointById(id) {
            const allCell = this.mapData.cellList
            for (var i = 0; i < allCell.length; i++) {
                const cell = allCell[i];
                if (cell.id == id) {
                    return cell
                }
            }
            return null
        }
        ,


        getTrianglePath(startPoint) {
            const height = 16;
            const side = parseInt((height * 0.5) + "")
            const sX = startPoint.x
            const sY = startPoint.y
            return sX + "," + sY + "L," +
                sX + "," + (sY - side) + "L," +
                (sX + height) + "," + sY + "L," +
                sX + "," + (sY + side) + "L" +
                sX + "," + sY + "L";
        }
        ,

        getPointList() {
            const list = []
            list.push({x: 275, y: 37})
            list.push({x: 275, y: 469})
            list.push({x: 429, y: 469})
            list.push({x: 429, y: 955})
            list.push({x: 486, y: 955})
            list.push({x: 486, y: 1214})
            list.push({x: 247, y: 1214})
            list.push({x: 247, y: 1345})

            const ratioList = []
            const mapContainerWidth = this.getMapContainerWidth();
            var ratio = 1.0;
            if (!this.isEditorMode) {
                ratio = mapContainerWidth / MAP_EDITOR_MAX_WIDTH;
            }
            printLog("====ratio:" + ratio)
            list.forEach((item) => {
                ratioList.push({
                    x: parseInt(item.x * ratio),
                    y: parseInt(item.y * ratio),
                })
            });
            return ratioList
        },

        drawRouterPath(raphaelObj) {
            let animConfig = this.mapData.animConfig
            if (animConfig && animConfig.attr) {
                const list = this.getPointList()
                // const endPoint = list[list.length - 1]
                var path = "M"
                list.forEach((item) => {
                    path += this.getPointString(item, true)
                })

                // path += (this.getPointString(endPoint, true) + this.getTrianglePath(endPoint))
                printLog("=====new draw path is:" + path)
                this.drawPath(raphaelObj,
                    path,
                    animConfig.duration,
                    {
                        stroke: animConfig.attr.stroke,
                        'stroke-width': animConfig.attr.strokeWidth,
                        'stroke-opacity': 1,
                        fill: animConfig.attr.fill,
                        'fill-opacity': animConfig.attr.fillOpacity
                    },
                    null);
            }
        }
        ,


        drawPath(canvas, pathstr, duration, attr, callback) {
            const guide_path = canvas.path(pathstr).attr({stroke: "none", fill: "none"});
            const path = canvas.path(guide_path.getSubpath(0, 1)).attr(attr);
            const total_length = guide_path.getTotalLength(guide_path);
            // const last_point = guide_path.getPointAtLength(0);
            const start_time = new Date().getTime();
            const interval_length = 50;
            const result = path;

            var interval_id = setInterval(function () {
                var elapsed_time = new Date().getTime() - start_time;
                var this_length = elapsed_time / duration * total_length;
                attr.path = guide_path.getSubpath(0, this_length);

                path.animate(attr, interval_length);
                if (elapsed_time >= duration) {
                    clearInterval(interval_id);
                    guide_path.remove();
                    // path.remove();
                    if (callback) {
                        callback();
                    }
                }
            }, interval_length);
            return result;
        }
        ,


        drawTestAnim(raphaelObj) {
            var sequence_path = ["M700,500L", "780,500L", "780,590L", "700,590z", "L14,-37", "L5,-40"];
            this.drawPath(raphaelObj,
                sequence_path,
                3500,
                {stroke: 'red', 'stroke-width': 4, 'stroke-opacity': 1, fill: 'none', 'fill-opacity': 0.0},
                function () {
                    // alert("All done"); // trigger whatever you want here
                });
        }


    },


}


</script>

<style rel="stylesheet/scss" lang="scss">
.condition-radio {
    width: 20px;
    height: 20px;
}

.condition-radio-label {
    font-size: 24px;
    color: black;
    margin-right: 10px;
}

.search-input-view {
    padding: 6px;
    border-radius: 4px;
    font-size: 26px;
    width: 220px;
    margin-right: 10px;
}

.search-input-button {
    font-size: 24px;
    padding: 4px 20px;
    border-radius: 4px
}


/* 隐藏滚动条 */

::-webkit-scrollbar {

    width: 0;

    height: 0;

    background-color: transparent;

    /* 隐藏滚动条轨道 */

    ::-webkit-scrollbar-track {

        background-color: transparent;

        /* 隐藏滚动条滑块 */

        ::-webkit-scrollbar-thumb {

            background-color: transparent;
        }
    }
}

.loading-mask {
    font-size: 16px;
    color: white;
}
</style>